<template>
  <div id="rpp-document" :style="format">
    <div class="d-flex">
      <div style="margin-right: 30px;">
        <img
          :src="institusi.logo || `/${config.logoCompress}`"
          width="120px"
          height="90px"
          alt
        />
      </div>
      <div class="w-100">
        <div class="text-right"><strong>Pekan Belajar ke-{{week}}</strong></div>
        <div class="header-title text-center">RENCANA PELAKSANAAN PEMBELAJARAN</div>
      </div>
    </div>
    <hr />
    <div>
      <div class="d-flex">
        <div class="font-size-14">
          <div>Nama Sekolah</div>
          <div>Mata Pelajaran</div>
          <div>Kelas/Semester</div>
          <div>Tahun Pelajaran</div>
          <div>Alokasi Waktu</div>
        </div>
        <div class="ml-4 mr-2 font-size-14">
          <div v-for="i in 5" :key="i">:</div>
        </div>
        <div class="font-size-14">
          <div>{{institusi.nama || config.name}}</div>
          <div>{{overview.namaMapel}}</div>
          <div>{{overview.namaLevel}} / {{semester}}</div>
          <div>{{tahunAjaran}}</div>
          <div>...</div>
        </div>
      </div>
    </div>
    <br />
    <div class="font-size-14">
      <div class="d-flex font-weight-bold">
        <div>Kompetensi Dasar</div>
        <div class="ml-4">:</div>
      </div>
      <div v-for="kd in kds" :key="kd.id">
        <div v-if="kd.nama_pengetahuan" class="d-flex">
          <div>3.{{kd.no}}</div>
          <div class="ml-4">{{kd.nama_pengetahuan}}</div>
        </div>
        <div v-if="kd.nama_keterampilan" class="d-flex">
          <div>4.{{kd.no}}</div>
          <div class="ml-4">{{kd.nama_keterampilan}}</div>
        </div>
      </div>
    </div>
    <br />
    <div class="font-size-14">
      <div class="d-flex font-weight-bold">
        <div>Landasan Syar'i</div>
        <div class="ml-4">:</div>
      </div>
      <div>{{dataRpp.landasan_syar_i}}</div>
    </div>
    <br />
    <div class="font-size-14">
      <ol class="font-weight-bold pl-3" type="A">
        <li class="pl-2" id="tujuan-pembelajaran">
          <div>TUJUAN PEMBELAJARAN</div>
          <div class="font-weight-normal font-size-14 text-justify" v-html="dataRpp.tujuan_pembelajaran">
            <!-- <div>Melalui proses pembelajaran ini, siswa diharapkan mampu:</div>
            <ol class="mr-3">
              <li>Mencirikan  perbedaan unsur, senyawa, dan campuran setelah mengamati tayangan video dengan seksama.</li>
              <li>Menyebutkan contoh unsur, senyawa dan campuran tersebut dengan benar.</li>
            </ol> -->
          </div>
          <br />
        </li>
        <li class="pl-2" id="kegiatan-pembelajaran-primary">
          <div>KEGIATAN PEMBELAJARAN</div>
          <div class="font-weight-normal" id="kegiatan-pembelajaran">
            <table
            class="w-100 mt-3
              table table-bordered table-responsive-sm" id="tabel-kp-1">
              <tr id="header-kp">
                <th style="vertical-align: middle;" class="text-center">Kegiatan</th>
                <th style="vertical-align: middle;" class="text-center">Deskripsi Kegiatan</th>
                <th style="vertical-align: middle;" class="text-center">Alokasi Waktu</th>
              </tr>
              <tr id="pendahuluan-kp">
                <td class="text-center judul" style="vertical-align: middle; min-width: 120px;">
                  Pendahuluan
                </td>
                <td class="text-justify deskripsi">
                  <ol class="pl-3" type="a">
                    <li>Guru menyiapakan fisik dan psikis siswa dengan menyapa dan memberi salam melalui zoom meeting.</li>
                    <li>Guru <strong>memotivasi </strong>belajar dengan memberi contoh-contoh kepada siswa tentang hal-hal yang berkaitan dengan materi</li>
                    <li>Guru menyampaikan manfaat dan tujuan pembelajaran serta langkah-langkah pembelajaran yang akan dilaksanakan.</li>
                    <li>Guru mengingatkan tentang menjaga kesehatan disaat pandemi dan senantiasa menggunakan masker keketika keluar rumah</li>
                  </ol>
                </td>
                <td class="text-center alokasi_waktu" style="min-width: 110px">25 menit</td>
              </tr>
              <tr id="penutup-kp">
                <td style="vertical-align: middle; min-width: 120px;" class="text-center judul">Penutup</td>
                <td class="text-justify deskripsi">
                </td>
                <td class="text-center alokasi_waktu" style="min-width: 110px;">10 menit</td>
              </tr>
            </table>
          </div>
        </li>
        <li class="pl-2" id="penilaian">
          <div>PENILAIAN</div>
          <div class="font-weight-normal">
            <table
              class="w-100 mt-3
              table table-bordered table-responsive-sm" id="tabel-penilaian">
              <tr class="row-main">
                <th colspan="3">Jenis Penilaian</th>
              </tr>
              <tr>
                <td>Sikap</td>
                <td>Pengetahuan</td>
                <td>Keterampilan</td>
              </tr>
              <tr id="penilaian-row">
                <td id="penilaian_sikap">
                </td>
                <td id="penilaian_pengetahuan">
                </td>
                <td id="penilaian_keterampilan">
                </td>
              </tr>
            </table>
          </div>
        </li>
        <!-- <div style="height: 250px;"></div> -->
        <li id="sumber-media-rpp" class="pl-2">
          <div>SUMBER DAN MEDIA</div>
          <div class="font-weight-normal">
            <ol>
              <li>Sumber Belajar : {{dataRpp.sumber_belajar}}</li>
              <li>Alat dan media : {{dataRpp.alat_media}}</li>
            </ol>
          </div>
        </li>
      </ol>
    </div>
    <div class="signature font-size-14" id="signature-rpp">
      <div class="text-right">Jakarta, {{lastUpdate}}</div>
      <div class="d-flex justify-content-between">
        <div style="max-width: 200px;" class="d-flex flex-column align-items-center">
          <div>
            Mengetahui,
          </div>
          <div class="text-center">
            Kepala {{institusi.nama || config.name}}
          </div>
          <div style="height: 100px"></div>
          <div>{{overview.kepalaSekolah.nama}}</div>
        </div>
        <div style="max-width: 200px;" class="d-flex flex-column align-items-center">
          <div style="height: 20px"></div>
          <div class="text-center">
            Guru Bidang Studi {{overview.namaMapel}}
          </div>
          <div style="height: 100px"></div>
          <div>{{user.nama}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import config from '@/config'
import JsPdf from 'jspdf'
import $ from 'jquery'
import moment from 'moment'
export default {
  props: {
    dataRpp: {
      type: Object,
      // required: true,
    },
    format: {
      type: Object,
      // required: true,
    },
    overview: {
      type: Object,
      // required: true,
    },
    isPrint: {
      type: Boolean,
      defaultValue: false,
    },
  },
  data() {
    return {
      baseUrl: window.location.origin,
      config: config.school,
      pageHeight: 1098,
      totalPage: 1,
    }
  },
  computed: {
    institusi() {
      return this.$store.state.master.institusi
    },
    week() {
      return Number(this.dataRpp.week) < 10 ? `0${this.dataRpp.week}` : this.dataRpp.week
    },
    user() {
      return this.$store.state.user.user
    },
    tahunAjaran() {
      if (this.overview.tahunAjaran) {
        return this.overview.tahunAjaran.tahun
      }

      return '-'
    },
    semester() {
      if (this.overview.tahunAjaran) {
        if (this.overview.tahunAjaran.semester === 'Ganjil') return 1
        if (this.overview.tahunAjaran.semester === 'Genap') return 2
      }
      return '-'
    },
    height() {
      return document.getElementById('rpp-document').clientHeight
    },
    kds() {
      return this.dataRpp.kd_master
    },
    tujuanPembelajaranDom() {
      const data = document.getElementById('tujuan-pembelajaran')
      return {
        offsetTop: data.offsetTop,
        height: data.clientHeight,
      }
    },
    kegiatanPembelajaranDom() {
      const data = document.getElementById('kegiatan-pembelajaran')
      return {
        offsetTop: data.offsetTop,
        height: data.clientHeight,
      }
    },
    pageBoundary() {
      return (this.pageHeight * this.totalPage) - this.marginBottom
    },
    lastUpdate() {
      return moment(this.dataRpp.updatedAt, 'YYYY-MM-DD').locale('id').format('DD MMMM YYYY')
    },
    marginBottom() {
      const marginBottom = 60
      return marginBottom + ((this.totalPage - 1) * 40)
    },
  },
  methods: {
    previewRpp() {
      // this.isExported = true
      const doc = new JsPdf({
        // orientation: 'p',
        unit: 'px',
        // format: 'a4',
        // putOnlyUsedFonts: true,
      })

      // tujuanPembelajaran.innerHTML = this.dataRpp.tujuan_pembelajaran
      this.tableKegiatanPembelajaran()
      this.tablePenilaian()
      this.sumberMedia()
      this.signature()
      const data = document.getElementById('rpp-document')
      // console.log(data)4
      doc.html(data, {
        callback: (doc) => {
          // doc.text('testestes', (doc.internal.pageSize.getWidth() * 0.40), (200))
          // const title = `REPORT ${tipeReport} ${murid} (${kelas}) ${tahunAjaran} ${semester}`
          const yFooter = doc.internal.pageSize.getHeight() - 10
          const totalPages = doc.internal.getNumberOfPages()
          const schoolName = this.institusi.nama || this.config.name
          for (let i = 1; i <= totalPages; i++) {
            doc.setPage(i)
            doc.setFontSize(10)
            doc.setTextColor(100)
            doc.text(schoolName, (doc.internal.pageSize.getWidth() * 0.40), (yFooter))
            doc.text('Page ' + i + ' of ' + totalPages, (doc.internal.pageSize.getWidth() * 0.88), (yFooter))
          }
          window.open(URL.createObjectURL(doc.output('blob')))
          // $('.inti-kp').remove()
          // $('.separator').remove()
          // $('#penutup-kp').appendTo('#tabel-kp-1')
          // $('.tabel-tambahan').remove()
          this.$emit('printDone')
        },
        html2canvas: {
          letterRendering: true,
          useCORS: true,
          scale: 0.6,
        },
        margin: [10, 10, 10, 10],
      })
    },
    tableKegiatanPembelajaran() {
      // const data = []

      // pendahuluan_kp
      const dataPendahuluan = this.dataRpp.pendahuluan_kp
      const pendahuluan = document.getElementById('pendahuluan-kp')
      const colPendahuluan = pendahuluan.getElementsByTagName('td')
      colPendahuluan.forEach((td, i) => {
        const className = td.className.split(' ')
        for (const key in dataPendahuluan) {
          if (!className.includes('judul') && className.includes(key)) {
            td.innerHTML = key === 'alokasi_waktu' ? dataPendahuluan[key] + ' menit' : `<div>${dataPendahuluan[key]}</div>`
          }
        }
      })
      // make condition whether its off from the page, will create div above table to send it to new page
      const bottomEdgeTujuanPembelajaran = this.tujuanPembelajaranDom.height + this.tujuanPembelajaranDom.offsetTop
      const bottomEdgePendahuluan = pendahuluan.clientHeight + pendahuluan.offsetTop
      if (bottomEdgeTujuanPembelajaran + bottomEdgePendahuluan > this.pageBoundary) {
        const divHeight = this.pageHeight - bottomEdgeTujuanPembelajaran - 10
        this.totalPage++
        const div = $('<div class="separator" id="separator-tujuan-pembelajaran"></div>')
        $(div).insertBefore($('#kegiatan-pembelajaran-primary'))
        $(div).css('height', divHeight + 'px')
      }

      //
      let numTable = 1
      const headerKp = document.getElementById('header-kp')
      const tabelKp1 = document.getElementById('tabel-kp-1')
      const headerKpHeightPlusPos = headerKp.clientHeight + tabelKp1.offsetTop
      let totalTableHeightNow = headerKpHeightPlusPos + bottomEdgePendahuluan

      const penutup = document.getElementById('penutup-kp')

      const dataInti = this.dataRpp.inti_kp
      dataInti.forEach(dat => {
        const templateTdInti1 = `
          <tr class="inti-kp" id="inti-kp-${dat.key}">
            <td rowspan='${dataInti.length}' style="vertical-align: middle; min-width: 120px;" class="text-center judul">Inti</td>
            <td class="text-justify deskripsi"></td>
            <td class="text-center alokasi_waktu">30 menit</td>
          </tr>
        `
        const templateTdInti2 = `
          <tr class="inti-kp" id="inti-kp-${dat.key}">
            <td class="text-justify deskripsi"></td>
            <td class="text-center alokasi_waktu">30 menit</td>
          </tr>
        `
        const template = dat.key === 1 ? templateTdInti1 : templateTdInti2
        $(template).insertBefore('#penutup-kp')
      })
      dataInti.forEach((dat, i) => {
        const inti = document.getElementById(`inti-kp-${dat.key}`)
        const colInti = inti.getElementsByTagName('td')
        colInti.forEach(td => {
          const className = td.className.split(' ')
          if (className.includes('deskripsi')) {
            const text = `<p><strong>Pertemuan Ke- ${dat.key} :</strong></p><br />`
            td.innerHTML = text + `<div>${dat.deskripsi}</div>`
          } else if (className.includes('alokasi_waktu')) {
            td.innerHTML = dat.alokasi_waktu + ' menit'
          }
        })
        const temporaryTotalTable = totalTableHeightNow + inti.clientHeight
        if (temporaryTotalTable > this.pageBoundary) {
          this.totalPage++
          numTable++
          const table = $(`<table id="tabel-kp-${numTable}"></table>`)
          $(table).insertAfter('#tabel-kp-' + (numTable - 1))
          for (let i = dat.key; i <= dataInti.length; i++) {
            const id = `#inti-kp-${i}`
            $(id).appendTo(table)
          }
          $('#penutup-kp').appendTo(table)
          $(table).addClass('w-100 mt-3 table table-bordered table-responsive-sm tabel-tambahan')
          const divHeight = (this.pageHeight * (this.totalPage - 1)) - totalTableHeightNow
          // console.log(divHeight, dat.key, totalTableHeightNow)
          const div = $(`<div class="separator" id='separator-kp-${(numTable - 1)}'></div>`)
          $(div).insertBefore('#tabel-kp-' + (numTable))
          $(div).css('height', divHeight + 'px')
          totalTableHeightNow = inti.clientHeight + this.pageHeight
          // }
        } else {
          totalTableHeightNow += inti.clientHeight
        }
      })

      const dataPenutup = this.dataRpp.penutup_kp
      const colPenutup = penutup.getElementsByTagName('td')
      colPenutup.forEach((td, i) => {
        const className = td.className.split(' ')
        for (const key in dataPenutup) {
          if (!className.includes('judul') && className.includes(key)) {
            td.innerHTML = key === 'alokasi_waktu' ? dataPenutup[key] + ' menit' : `<div>${dataPenutup[key]}</div>`
          }
        }
      })

      const temporaryTotalTable = totalTableHeightNow + penutup.clientHeight
      if (temporaryTotalTable > this.pageBoundary) {
        this.totalPage++
        numTable++
        const table = $(`<table id="tabel-kp-${numTable}"></table>`)
        $(table).insertAfter('#tabel-kp-' + (numTable - 1))
        $('#penutup-kp').appendTo(table)
        $(table).addClass('w-100 mt-3 table table-bordered table-responsive-sm tabel-tambahan')
        const divHeight = (this.pageHeight * (this.totalPage - 1)) - totalTableHeightNow
        const div = $(`<div class="separator" id='separator-kp-${(numTable - 1)}'></div>`)
        $(div).insertBefore('#tabel-kp-' + (numTable))
        $(div).css('height', divHeight + 'px')
      }

      // const data = document.createElement('table')
    },
    tablePenilaian() {
      const penilaian = document.getElementById('penilaian-row')
      const colPenilaian = penilaian.getElementsByTagName('td')
      colPenilaian.forEach((td, i) => {
        const id = td.id
        for (const key in this.dataRpp) {
          if (id === key) {
            td.innerHTML = this.dataRpp[key]
          }
        }
      })

      const penilaianPrimary = document.getElementById('penilaian')

      const bottomEdgePenilaian = penilaianPrimary.clientHeight + penilaianPrimary.offsetTop
      const bottomEdgeKegiatanPembelajaran = this.kegiatanPembelajaranDom.height + this.kegiatanPembelajaranDom.offsetTop
      if (bottomEdgePenilaian > this.pageBoundary) {
        const divHeight = this.pageBoundary - bottomEdgeKegiatanPembelajaran
        this.totalPage++
        const div = $('<div class="separator" id="separator-penilaian"></div>')
        $(div).insertBefore($('#penilaian'))
        $(div).css('height', divHeight + 'px')
      }
    },
    sumberMedia() {
      const sumberMedia = document.getElementById('sumber-media-rpp')
      const penilaianPrimary = document.getElementById('penilaian')

      const bottomEdgeSumberMedia = sumberMedia.clientHeight + sumberMedia.offsetTop + 20
      const bottomEdgePenilaian = penilaianPrimary.clientHeight + penilaianPrimary.offsetTop
      if (bottomEdgeSumberMedia > this.pageBoundary) {
        const divHeight = (this.pageHeight * this.totalPage) - bottomEdgePenilaian
        this.totalPage++
        const div = $('<div class="separator" id="separator-sumber-media-rpp"></div>')
        $(div).insertBefore($('#sumber-media-rpp'))
        $(div).css('height', divHeight + 'px')
      }
    },
    signature() {
      const signature = document.getElementById('signature-rpp')
      const sumberMedia = document.getElementById('sumber-media-rpp')

      const bottomEdgeSignature = signature.clientHeight + signature.offsetTop
      const bottomEdgeSumberMedia = sumberMedia.clientHeight + sumberMedia.offsetTop
      console.log(this.pageBoundary, bottomEdgeSignature)
      if (bottomEdgeSignature > this.pageBoundary) {
        const divHeight = this.pageBoundary - bottomEdgeSumberMedia + 20
        this.totalPage++
        const div = $('<div class="separator" id="separator-signature-rpp"></div>')
        $(div).insertBefore($('#signature-rpp'))
        $(div).css('height', divHeight + 'px')
      }
    },
  },
  watch: {
    isPrint(newVal) {
      if (newVal) {
        this.previewRpp()
      }
    },
  },
}
</script>

<style lang="scss">
#rpp-document {
  // border: 1px solid black;
  padding: 50px 30px;
  .header-title {
    font-size: 18px;
    letter-spacing: 2px;
    border: 1px solid black;
    padding: 5px 10px;
  }

  p {
    font-family: "Montserrat", sans-serif !important;
    font-size: 14px!important;
  }

  .table-bordered {
    border: 0.5px solid black;
    td, th {
      border: 0.5px solid black;
    }
    // & > tr {
    //   & > td {
    //     border-top-width: 0;
    //     border-left-width: 0;

    //     &:last-child {
    //       border-right-width: 0;
    //     }
    //   }

    //   &:last-child {
    //     & > td {
    //       border-bottom-width: 0;
    //     }
    //   }
    // }
  }

  .signature {
    padding: 0 80px
  }

  // ol {
  //   li {
  //     // padding-top: 7px;
  //    div {
  //      padding-top: 4px;
  //    }
  //   }
  // }
}
</style>
